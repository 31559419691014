import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './assets/logo-beer.png'
import loading from './assets/loading.svg'
import insta from './assets/insta.png'
import Feed from "react-instagram-authless-feed"

function App() {
  const [step, setStep] = useState(1)
  let url = ''
   return (
    <div className="App w-[100vw] h-[100vh] overflow-x-hidden">
      <div className="bg-[#000] w-[100vw] min-h-[100vh] flex flex-col items-center justify-center">
        <img src={logo} alt="logo" className="md:w-2/12 w-6/12 animate-bounce antialiased transition-all" />
        <button type="button" className="bg-slate-200 mx-4 rounded flex justify-center items-center flex-row leading-tight text-xl p-4 " disabled>
          <img src={loading} className="animate-spin h-5 w-5 mr-3" alt=''/>
          Em breve nosso novo site, acesse nosso instagram até lá!
        </button>
        <a title='' href='https://www.instagram.com/redebeer4u/' target='_blank' rel="noreferrer" className="flex justify-center items-center flex-row leading-tight text-xl p-4 ">
          <img src={insta} alt="logo" className="md:w-1/2 w-full mt-4 antialiased transition-all" />
        </a>
        
      </div>
    </div>
  );
}

export default App;
